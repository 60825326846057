<template>
  <div class="Jepaas-detail-bar background-F5F5F5">
    <el-backtop />
    <div
      class="box_top"
    >
      <Header />
    </div>
    <div class="je-detail-content">
      <!-- 标题 -->
      <div class="background-FFFFFF padding-LR-20 padding-TB-20">
        <div class="color-3F3F3F font-weight-600">
          <span class="font-size-30">白条消费</span>
          <!-- <span class="font-size-18 margin-left-15">BT202003100001</span> -->
        </div>
        <!-- <div class="color-A9ABA9 font-size-14 margin-top-5">
          <span>白条审核资质：张思思  2020-01-13 11:35:33  </span>
        </div> -->
        <div class="margin-top-20 detail-wrapper">
          <el-row class="font-weight-600 color-3F3F3F">
            <el-col :span="4">
              <div class="font-size-16">
                可用额度：
              </div>
              <div class="margin-top-10 font-size-24 padding-left-30 limit-num">
                <span>{{ BTbasicInfo.canUse && BTbasicInfo.canUse.toFixed(2) }}</span>
                <span v-if="isWhiteBarPay">0</span>元
              </div>
            </el-col>
            <el-col :span="4">
              <div class="font-size-16">
                已用白条额度：
              </div>
              <div class="margin-top-10 font-size-24 padding-left-30 limit-num">
                {{ BTbasicInfo.canUse && BTbasicInfo.used.toFixed(2) }}
                <span v-if="isWhiteBarPay">0</span>元
              </div>
            </el-col>
            <el-col :span="4">
              <div class="font-size-16">
                已结算：
              </div>
              <div class="margin-top-10 font-size-24 padding-left-30 limit-num">
                {{ BTbasicInfo.canUse && BTbasicInfo.settled.toFixed(2) }}
                <span v-if="isWhiteBarPay">0</span>元
              </div>
            </el-col>
            <el-col :span="4">
              <div class="font-size-16">
                未结算：
              </div>
              <div class="margin-top-10 font-size-24 padding-left-30 limit-num">
                {{ BTbasicInfo.canUse && BTbasicInfo.noSettled.toFixed(2) }}
                <span v-if="isWhiteBarPay">0</span>元
              </div>
            </el-col>
          </el-row>

          <el-button
            type="danger"
            size="small"
            icon="jeicon jeicon-lightning-on"
            class="pay-btn"
            @click="openOnekeyTOpay"
          >
            一键立即结算
          </el-button>
        </div>
      </div>
      <!-- 白条消费tabs切换 -->
      <div class="background-FFFFFF margin-top-20 border-radius-5">
        <el-tabs
          v-model="activeName"
          class="explainsBar"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            label="未结算"
            name="WJS"
          />
          <el-tab-pane
            label="已结算"
            name="YJS"
          />
          <el-tab-pane
            label="全部订单"
            name="ALL"
          />
        </el-tabs>
      </div>
      <!-- 白条表单数据 -->
      <div
        v-if="activeName == 'WJS'"
        class="margin-top-20 font-size-16"
      >
        <i class="jeicon jeicon-fixed-income color-F9514F font-size-20  margin-left-10 margin-right-10" />
        <span class="color-3F3F3F font-weight-500">未结算总金额：</span>
        <span class="color-F9514F font-weight-500">{{ moneyAll.toFixed(2) }}元</span>
      </div>
      <div
        class="list-bt background-FFFFFF margin-top-10 padding-LR-20 padding-TB-10 position-relative"
      >
        <Loading :loadingData="{isShow: isLoading}" />
        <TableModel
          v-if="!isNodata"
          :pay-data="{data: list, totalCount: totalCount}"
          :isNodata="isNodata"
          @refreshList="refreshList"
          v-on="$listeners"
        />
        <div
          v-if="isNodata || isWhiteBarPay"
          style="text-align: center;padding: 120px 0"
        >
          <img
            src="../../../../detail/assets/images/alreadyIndent/nullData.png"
            alt
          >
          <div class="color-BEBEBE font-size-20">
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <OnekeySettle
      ref="onekeySettle"
      @refreshList="refreshList"
      v-on="$listeners"
    />
  </div>
</template>

<script type="text/ecmascript-6">
import Header from '@/components/Header';
import TableModel from './components/tableModel';
import OnekeySettle from './components/onekeySettle';
import { getwhitebarList, getwhitebarDetail } from '../../../actions/actions';

export default {
  components: {
    Header,
    TableModel,
    OnekeySettle,
  },
  data() {
    return {
      activeName: 'WJS',
      list: [],
      curPage: 1,
      totalCount: 0,
      BTbasicInfo: {},
      moneyAll: 0,
      isNodata: false,
      isLoading: true,
      isWhiteBarPay: true, // 是否拥有白条支付的权限，以及账单是否显示
    };
  },
  created() {
    this.getBasicInfo();
    this.getData();
    this.getAlldatas();
  },
  methods: {
    refreshList() {
      this.getBasicInfo();
      this.getData();
      this.getAlldatas();
    },
    // 获取基础信息
    getBasicInfo() {
      getwhitebarDetail().then((result) => {
        if (!result.success) {
          return;
          // return this.$message({
          //   type: 'error',
          //   message: result.message,
          // });
        }
        this.isWhiteBarPay = false;
        this.BTbasicInfo = result.obj;
      });
    },
    // 获取白条消费列表
    getData() {
      this.isNodata = false;
      this.isLoading = true;
      this.list = [];
      getwhitebarList(this.getParams()).then((result) => {
        this.isLoading = false;
        if (!result.success) {
          return;
        }
        if (result.totalCount == 0) {
          this.isNodata = true;
        }
        this.settingOrderData(result.rows || []);
        this.totalCount = result.totalCount;
      });
    },
    // 接口数据对照格式处理
    settingOrderData(data) {
      const testD = [];
      data.forEach((order) => {
        const orderList = {
          payLoading: false, // 当前一旦点击支付后，支付状态转换为转圈的状态
          photo: order.photo.split('*')[1],
          urlCode: order.productCode && order.productCode.toLocaleLowerCase() || '', // 商品的专属code
          url: order.url, // 点击订单标题需要跳转的地方
          type: order.type, // 商业证书的编号等
          date: order.orderDate || '无下单日期', // 下单日期
          orderId: order.orderId, // 订单的唯一ID
          orderNumber: order.orderBh || '订单无', // 订单编号
          detailTitle: `【${order.spmc || '无'}】${order.productName || ''}`, // 详情的标题
          details: order.pzmx && this.orderDetailsFormat(order.type, order) || '', // 订单上面的详情
          credentialNumber: order.glzs || '', // 关联证书编号
          Monetary: {
            number: order.cjjg, // 价格
            type: order.orderStatus, // 支付的状态
            text:
              (order.orderStatus != 'ZFCG'
                && { DZF: '待支付' }[order.orderStatus])
              || { YQX: '已取消' }[order.orderStatus]
              || { ZFSB: '支付失败' }[order.orderStatus]
              || '新状态',
          },
          allowPayType: (order.yxzffs && order.yxzffs.split(',')) || [], // 允许使用的支付方式
          payType: {
            code: order.payType,
            icon:
              {
                ali: 'jeicon jeicon-alipay color-069CE7',
                wechat: 'jeicon jeicon-wechatpay color-24AF41',
                other: 'jeicon jeicon-rmb-on color-FBB11A',
              }[order.payType] || 'jeicon jeicon-ious-pay color-3F3F3F',
            text:
              { ali: '支付宝支付', wechat: '微信支付', other: '线下支付' }[
                order.payType
              ] || '白条支付',
          }, // 支付成功的支付方式
          sets: this.orderSetFormat(order.orderStatus, order.type, order.glzs), // 操作
          isSettlement: order.isSettlement,
        };
        testD.push(orderList);
      });
      this.list = testD;
    },
    // 接口格式 --- 标题下方的详细内容
    orderDetailsFormat(type, datas) {
      let obj = {};
      const data = datas.pzmx;
      if (type == 'SYZS' && datas.pzmx) {
        obj = {
          canList:
            (data.find(MX => MX.code == 'KJBS')
              && data.find(MX => MX.code == 'KJBS').count)
            || 0, // 可建表数
          canFunc:
            (data.find(MX => MX.code == 'KJGNS')
              && data.find(MX => MX.code == 'KJGNS').count)
            || 0, // 可建功能
          canFlow:
            (data.find(MX => MX.code == 'KJLCS')
              && data.find(MX => MX.code == 'KJLCS').count)
            || 0, // 可建流程
          canUser:
            (data.find(MX => MX.code == 'SYYHS')
              && data.find(MX => MX.code == 'SYYHS').count)
            || 0, // 可使用人数
          canMachine:
            (data.find(MX => MX.code == 'SYJQS')
              && data.find(MX => MX.code == 'SYJQS').count)
            || 0, // 可建机器数
          topTitle:
            (data.find(MX => MX.code == 'DBXSZ')
              && data.find(MX => MX.code == 'DBXSZ').count)
            || '无', // 顶部样式
          topHref:
            (data.find(MX => MX.code == 'DBLJ')
              && data.find(MX => MX.code == 'DBLJ').count)
            || '无', // 顶部链接
          topLoading:
            (data.find(MX => MX.code == 'SJJZZY')
              && data.find(MX => MX.code == 'SJJZZY').count)
            || '无', // 数据加载字样
          companyDetails:
            (data.find(MX => MX.code == 'GSXXZY')
              && data.find(MX => MX.code == 'GSXXZY').count)
            || '无', // 公司信息字样
        };
      } else if (type == 'RGFW') {
        obj = {
          content: `单价: ${datas.orderDj}，数量：${datas.orderCount} `,
        };
        // 如果是VIP用户就显示
        if (datas.cpzl == 'VIP' && datas.pzmx) {
          obj = {
            content: datas.pzmx[0].name,
          };
        }
      } else {
        obj = {
          content: data[0].descriptionInfo || data[0].description,
        };
      }
      return obj;
    },
    /**
     * @name: 接口格式 -- 操作按钮的过滤
     * @Descripttion: 根据参数支付状态 和关联证书等进行过滤按钮权限  banClick是否允许点击
     * @param {type} 购买商品的编号：SYZS SJZS ZQZJ 等等
     * @param {status} 支付的状态 ZFCG ZFSB DZF 等等
     * @param {number} 关联证书编号
     * @returns:
     */

    // 接口格式 --- 操作功能显示
    orderSetFormat(status, type, number) {
      let setObj = [
        {
          name: '使用说明',
          code: 'instructions',
        },
        {
          name: '下载证书',
          code: 'downLoad',
          banClick: !number,
        },
        {
          name: '使用详情',
          code: 'showDetail',
          banClick: !number,
        },
        // {
        //   name: '升级证书',
        //   code: 'upload',
        //   banClick: !number,
        // },
        {
          name: '修改版权',
          code: 'editCopyRight',
          banClick: !number,
        },
      ];
      if (status == 'DZF') {
        setObj = [
          {
            name: '取消订单',
            code: 'closeOrder',
          },
          {
            name: '立即支付',
            code: 'payType',
          },
        ];
      }
      if (status == 'ZFSB') {
        setObj = [
          {
            name: '取消订单',
            code: 'closeOrder',
          },
          {
            name: '重新支付',
            code: 'payType',
          },
        ];
      }
      if (status == 'YQX') {
        setObj = [
          {
            name: '重新购买',
            code: 'buyAgain',
          },
        ];
      }
      if (status == 'ZFCG') {
        switch (type) {
          case 'SYZS':
            break;
          case 'ZQZJ':
            setObj = [
              {
                name: '下载钥匙',
                code: 'downLoadKey',
              },
            ];
            break;
        }
      }
      return setObj;
    },
    // 处理参数
    getParams() {
      return {
        type: this.activeName,
        pageSize: 20,
        currPage: this.curPage,
      };
    },
    // tab切换
    handleTabClick() {
      this.getData();
    },
    // 获取所有未结算数据
    getAlldatas() {
      getwhitebarList({
        type: 'WJS',
        pageSize: 20,
        currPage: -1,
      }).then((result) => {
        if (!result.success) {
          return;
        }
        const rows = result.rows || [];
        rows.forEach((item) => {
          this.moneyAll += item.cjjg;
        });
      });
    },
    // 点击一键立即结算
    openOnekeyTOpay() {
      this.$refs.onekeySettle.onekeyVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
  @import "../../../style/shop/util";
</style>
<style lang="less">
  ::-webkit-scrollbar {
    display: none;
  }
  .Jepaas-detail-bar {
    position: relative;
    width: 100%;
    min-height: 100vh;
    .list-bt {
      min-height: 550px;
    }
    .el-backtop {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      z-index: 10;
      border-radius: 4px;
      width: 52px;
      height: 52px;
      right: 2% !important;
      bottom: 43px !important;
      font-size: 24px;
      color: #ff3041;
      &:hover {
        background: #ff3041;
        > i {
          color: #ffffff;
        }
      }
    }
    .box_top {
      width: 100%;
      height: 80px;
      background: linear-gradient(
              270deg,
              rgba(252, 103, 81, 1) 0%,
              rgba(246, 71, 77, 1) 100%
      );
    }
    .je-detail-content {
      margin: 0 auto;
      width: 1250px;
      .detail-wrapper {
        position: relative;
        .pay-btn {
          position: absolute;
          right: 50px;
          top: 5px;
          .jeicon {
            font-size: 14px;
          }
        }
        .limit-num {
          white-space: nowrap;
        }
      }
      .title {
        height: 42px;
        font-size: 30px;
        font-weight: 600;
        color: rgba(63, 63, 63, 1);
        line-height: 42px;
      }
      .question {
        height: 70px;
        background: rgba(255, 242, 240, 1);
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        font-weight: 500;
        .el-button {
          font-size: 26px;
          margin-left: 10px;
        }
      }
      .explainsBar {
        margin-top: 30px;

        .el-tabs__header {
          margin-bottom: 0!important;
        }
        .el-tabs__nav {
          width: 100%;
        }
        .el-tabs__item {
          text-align: center;
          width: 33%;
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(63, 63, 63, 1);
          height: 100%;
          line-height: normal;
        }
        .el-tabs__active-bar {
          background-color: #f84e4e;
          width: 33% !important;
        }
      }
    }
  }
</style>
