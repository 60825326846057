<!-- 公共的订单表格显示 -->
<template>
  <div class="je-jepass-whiteBar">
    <div v-if="!isNodata">
      <div
        v-for="(pay, _index) in payData.data"
        :key="_index"
      >
        <el-row class="padding-bottom-15 padding-top-10 border-bottom-2-solid-F2F2F2">
          <el-col :span="6">
            <div class="font-size-16 color-A9ABA9">
              {{ pay.date }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="font-size-16 color-A9ABA9">
              订单号：{{ pay.orderNumber }}
            </div>
          </el-col>
        </el-row>
        <el-row class="padding-top-15 padding-left-10 padding-bottom-10 border-bottom-2-solid-F2F2F2">
          <!-- 购买的商品详细信息 -->
          <el-col
            :id="`alreadyTab${_index}Id`"
            :span="12"
            class="height-100-bai"
          >
            <el-row
              style="display: flex;align-items: center;"
              class="border-right-2-solid-F2F2F2 height-100-bai padding-right-15 padding-TB-15 height-120"
            >
              <el-col
                :span="4"
                class="border-radius-5 padding-top-10"
              >
                <img
                  class="img-65"
                  v-lazy="'/jepaas/document/preview?fileKey=' + pay.photo"
                >
              </el-col>
              <el-col :span="20">
                <div
                  :class="pay.Monetary.type == 'YQX' && 'color-A9ABA9'"
                  class="color-3F3F3F font-size-18 font-weight-500 cursor-pointer-hover-F9514F"
                  @click="openUrl(pay)"
                >
                  {{ pay.detailTitle }}
                </div>
                <span
                  v-if="['ZSSJ', 'SYZS'].includes(pay.type)"
                  class="font-size-14 color-A9ABA9 margin-top-10"
                >
                  可建表数：{{ pay.details.canList }}，
                  可建功能数：{{ pay.details.canFunc }}，
                  可建流程数：{{ pay.details.canFlow }}，
                  使用人数：{{ pay.details.canUser }}，
                  服务器数：{{ `${pay.details.canMachine}${pay.credentialNumber && '，'}` }}
                </span>
                <div
                  v-if="pay.details.content"
                  class="font-size-14 color-A9ABA9 margin-top-10"
                >
                  {{ pay.details.content }}
                </div>
                <span
                  v-if="['ZSSJ', 'SYZS'].includes(pay.type)"
                  class="font-size-14 color-A9ABA9"
                >
                  <span v-if="pay.details.topTitle">
                    顶部显示:
                    {{ pay.details.topTitle }}，
                  </span>
                  <span v-if="pay.details.topLoading">
                    数据加载:
                    {{ pay.details.topLoading }}，
                  </span>
                  <span v-if="pay.details.companyDetails">
                    公司信息:
                    {{ pay.details.companyDetails }}
                  </span>
                </span>
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :span="5"
            class="border-right-2-solid-F2F2F2 height-120"
            style="display: flex;align-items: center;justify-content: center"
          >
            <div
              v-if="pay.Monetary.type == 'ZFCG' && ['ZSSJ', 'SYZS'].includes(pay.type) && !pay.credentialNumber"
              class="font-size-18 color-376BD0 text-status-center font-weight-600"
            >
              <span class="color-A9ABA9">审核中</span>
            </div>
            <div
              v-if="pay.credentialNumber"
              class="font-size-18 color-376BD0 text-status-center font-weight-600"
            >
              <span
                :class="pay.Monetary.type == 'YQX' && 'color-A9ABA9'"
              >{{ pay.credentialNumber }}</span>
            </div>
          </el-col>
          <el-col
            :span="7"
            class="height-120"
            style="display: flex; justify-content: center"
          >
            <div class="right-col">
              <div class="font-size-18 color-F9514F font-weight-500">
                ￥{{ pay.Monetary.number.toFixed(2) || '未知' }}
              </div>

              <el-dropdown
                v-if="pay.isSettlement != '1'"
                class="margin-top-10"
                trigger="click"
                @command="payCommand"
              >
                <div class="text-status-center list-pay-btn">
                  <span class="el-dropdown-link font-size-14 cursor-pointer-hover-F9514F">
                    立即结算
                    <i class="el-icon-arrow-down el-icon--right" />
                  </span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="pay.Monetary.number <= 3000"
                    :command="{type: 'wechat', data: pay}"
                  >
                    <div class="color-3F3F3F">
                      <i class="jeicon jeicon-wechatpay color-24AF41 vertical-align-middle" />
                      <span class="vertical-align-middle">微信支付</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item :command="{type: 'ali', data: pay}">
                    <div class="color-3F3F3F">
                      <i class="jeicon jeicon-alipay color-069CE7 vertical-align-middle" />
                      <span class="vertical-align-middle">支付宝支付</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item :command="{type: 'XXZF', data: pay}">
                    <div class="color-3F3F3F">
                      <i class="jeicon jeicon-rmb-on color-FBB11A vertical-align-middle" />
                      <span class="vertical-align-middle">线下支付</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div
                class="color-3F3F3F font-size-16"
                v-else
              >
                <i class="jeicon jeicon-rmb-on color-FBB11A vertical-align-middle" />
                <span class="vertical-align-middle margin-left-5">已结算</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div
      class="text-status-center margin-top-20 padding-bottom-20"
      v-if="payData.totalCount > 20"
    >
      <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="payData.totalCount"
      />
    </div>

    <PayDialog
      ref="payDialog"
      :payUrl="payTypeData"
      @reloadBTJS="replaceList"
      @openPhoneDialog="openPhone"
      v-on="$listeners"
    />
    <PhoneDialog
      ref="phoneDialog"
    />
  </div>
</template>

<script>
import PayDialog from '@/pages/detail/components/payDialog';
import PhoneDialog from '@/pages/detail/components/phone';
import { startPay } from '@/pages/detail/actions/actions';
import { getToken } from '../../../../../../util/auth';

export default {
  components: {
    PayDialog, PhoneDialog,
  },
  props: {
    payData: {
      type: Object,
      default: () => {},
    },
    isNodata: {
      type: Boolean,
      default: false,
    },
  },
  // 声明依赖  让子组件paydialog 获取这个类型
  provide() {
    return {
      productType: '',
    };
  },
  data() {
    return {
      payTypeData: {
        transactionType: 'BTJS*',
      },
      fullscreenLoading: null,
    };
  },
  methods: {
    // 点击刷新列表按钮
    replaceList() {
      this.$emit('refreshList');
    },
    // 点击标题进行连接跳转
    openUrl(url) {
      if (!url) {
        this.$message.error('不存在url');
        return;
      }
      window.open(`shop/${url.urlCode}.html`);
    },
    //  点击操作中的功能
    setClick(set) {
      switch (set.code) {
        case 'downLoad': // 下载证书的弹出框
          this.downLoadCertificate = true;
          break;
        case 'editCopyRight': // 修改版权信息
          this.editCopyRight = true;
          break;
        case 'showDetail': // 使用详情
          this.$router.push({
            path: '/certificateShow',
          });
          break;
      }
    },
    getElementHeight(id) {
      if (!document.getElementById(id)) return;
      return document.getElementById(id).clientHeight;
    },
    // 立即结算
    payCommand(em) {
      if (!getToken()) {
        return this.$message({
          type: 'error',
          message: '您暂未登录，请先去登录！',
          duration: 5000,
        });
      }
      switch (em.type) {
        // 微信支付
        case 'wechat':
          this.startPaying(em, (orderInfo, payinfo) => {
            this.$refs.payDialog.qrcodeImg = payinfo.data;
            this.$refs.payDialog.title = '微信支付提示';
            this.$refs.payDialog.amountPayable = orderInfo.Monetary.number;
            this.$refs.payDialog.payTime = payinfo.date; // 二维码生成时间
            this.$refs.payDialog.payComputeTime(); // 开始倒计时
            this.$refs.payDialog.orderId = orderInfo.orderId;
            this.$refs.payDialog.orderCode = orderInfo.orderNumber; // 订单编号
            this.$refs.payDialog.ids = orderInfo.orderId;
            this.$refs.payDialog.isBTJS = 'BTJS*';
            setTimeout(() => {
              this.fullscreenLoading.close();
              this.$refs.payDialog.payVisible = true;
            }, 500);
          });
          break;
        // 支付宝支付
        case 'ali':
          this.startPaying(em, (orderInfo, payinfo) => {
            const newTab = window.open('about:blank');
            const div = document.createElement('div');
            // newTab.document.charset = 'UTF-8';
            div.innerHTML = payinfo.data;
            newTab.document.body.appendChild(div);
            newTab.document.forms.alipaysubmit.submit();
            this.$refs.payDialog.title = '支付宝支付提示';
            this.$refs.payDialog.amountPayable = orderInfo.Monetary.number;
            this.$refs.payDialog.orderId = orderInfo.orderId;
            this.$refs.payDialog.orderCode = orderInfo.orderNumber; // 订单编号
            this.$refs.payDialog.ids = orderInfo.orderId;
            this.$refs.payDialog.isBTJS = 'BTJS*';
            setTimeout(() => {
              this.fullscreenLoading.close();
              this.$refs.payDialog.payVisible = true;
            }, 500);
          });
          break;
        // 线下支付
        case 'XXZF':
          this.openPhone();
          break;
        default:
          break;
      }
    },
    // 立即支付
    startPaying(em, callback) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: '拼命加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        customClass: 'je-pay-loading',
      });
      startPay({
        orderId: em.data.orderId,
        type: em.type,
        ids: em.data.orderNumber,
        transactionType: 'BTJS*',
      }).then((result) => {
        if (!result.success) {
          this.fullscreenLoading.close();
          if (result.errorCode == '5101') {
            return this.$message({
              type: 'error',
              message: '当前支付方式异常，请切换支付方式，或者稍后重试！',
            });
          }
          return this.$message({
            type: 'error',
            message: result.message || '支付失败！',
          });
        }
        return callback(em.data, result.obj);
      });
    },
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
  @import "../../../../style/shop/util";
</style>
<style lang="less">
.je-jepass-whiteBar {
  .el-pagination.is-background .el-pager .active {
    background-color: #F6474E!important;
  }
  .right-col {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .list-pay-btn {
      padding: 3px 6px;
      background:rgba(244,244,244,1);
      border-radius: 4px;
      &:hover {
        background:rgba(244,244,244,.5);
      }
    }
  }
}
.je-pay-loading {
  .el-loading-spinner {
    i {
      font-size: 50px;
    }
    .el-loading-text {
      font-size: 24px;
    }
  }
}
</style>
