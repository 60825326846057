<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-16 11:14:13
 * @LastEditTime: 2020-05-15 11:28:17
 -->
<template>
  <el-dialog
    title="结算"
    append-to-body
    :close-on-click-modal="false"
    width="70%"
    :visible.sync="onekeyVisible"
    custom-class="settle-dialog"
  >
    <div class="background-FFFFFF padding-TB-10 padding-LR-20 border-radius-5">
      <el-row class="font-weight-500">
        <el-col :span="6">
          <div class="font-size-20 color-3F3F3F orderDate">
            订单日期
          </div>
        </el-col>
        <el-col :span="5">
          <div class="font-size-20 color-3F3F3F orderNum">
            订单编号
          </div>
        </el-col>
        <el-col :span="6">
          <div class="font-size-20 color-3F3F3F text-status-center">
            商品名称
          </div>
        </el-col>
        <el-col :span="7">
          <div class="font-size-20 color-3F3F3F text-status-center">
            金额
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 全选 -->
    <div
      v-if="orderListData.length > 0"
      class="margin-top-10 padding-left-15"
    >
      <el-checkbox
        :indeterminate="indeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >
        全选
      </el-checkbox>
    </div>
    <!-- 主要内容 -->
    <div class="margin-top-10 settleTable">
      <div
        class="background-FFFFFF padding-LR-15 line-height-54 height-54  border-radius-5 position-relative"
      >
        <Loading :loadingData="{isShow: loading}" />
        <div v-if="orderListData.length > 0">
          <el-row
            v-for="(alreadyList, _index) in orderListData"
            :key="_index"
            class="border-bottom-2-solid-F2F2F2 height-align padding-bottom-10 padding-top-10"
          >
            <el-col
              :span="1"
              class=""
            >
              <el-checkbox
                v-model="alreadyList.check"
                class="vertical-align-middle"
                @change="handleCheckedCitiesChange(alreadyList)"
              />
            </el-col>
            <el-col :span="5">
              <div class="font-size-18 color-3F3F3F ">
                {{ alreadyList.orderDate }}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="font-size-18 color-3F3F3F">
                {{ alreadyList.orderBh }}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="font-size-18 color-3F3F3F text-status-center">
                {{ alreadyList.productName }}
              </div>
            </el-col>
            <el-col :span="7">
              <div class="font-size-18 color-F9514F text-status-center">
                ¥{{ alreadyList.cjjg.toFixed(2) }}
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 无订单 -->
        <div
          v-else
          style="text-align: center;margin:0 auto;padding: 50px 0"
        >
          <img
            src="../../../../../detail/assets/images/alreadyIndent/nullData.png"
            alt
          >
          <div class="color-BEBEBE font-size-14">
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <div class="background-FFFFFF text-status-center padding-top-20">
      <el-pagination
        background
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="totalCount"
        @current-change="pageHandleChange"
      />
    </div>
    <div
      class="line_con padding-bottom-30 padding-top-30 font-size-22 color-3F3F3F text-status-right"
    >
      应付金额：
      <span
        v-loading="moneyAllLoading"
        class="color-F9514F"
      > {{ moneyAll.toFixed(2) }}元 </span>
    </div>

    <div class="paytype-box">
      <div @click.stop.prevent="doPay('wechat')">
        <i class="jeicon jeicon-wechatpay" />
        <div>微信支付</div>
      </div>
      <div @click.stop.prevent="doPay('ali')">
        <i class="jeicon jeicon-alipay" />
        <div>支付宝支付</div>
      </div>
      <div @click.stop.prevent="doPay('XXZF')">
        <i class="jeicon jeicon-rmb-on" />
        <div>线下支付</div>
      </div>
    </div>
    <PayDialog
      ref="payDialog"
      :payUrl="payTypeData"
      @openPhoneDialog="openPhone"
      @reloadBTJS="loadDatas"
      v-on="$listeners"
    />
    <PhoneDialog
      ref="phoneDialog"
    />
  </el-dialog>
</template>

<script>
import PayDialog from '@/pages/detail/components/payDialog';
import PhoneDialog from '@/pages/detail/components/phone';
import { startPay } from '@/pages/detail/actions/actions';
import { getwhitebarList } from '../../../../actions/actions';
import { getToken } from '../../../../../../util/auth';

export default {
  name: 'OnekeySettle',
  components: {
    PhoneDialog, PayDialog,
  },
  // 声明依赖  让子组件paydialog 获取这个类型
  provide() {
    return {
      productType: '',
    };
  },
  data() {
    return {
      indeterminate: false, // 半选状态
      allDatas: [], // 所有的数据不会因为是否选中而变少
      onekeyVisible: false,
      orderListData: [], // 订单数据
      totalCount: 0,
      loading: false,
      checkAll: false, // 全选
      checkData: [], // 选中数据
      selectionIds: [], // 收集选中的订单ID
      selectionCodes: [], // 收集选中的订单编号
      moneyAll: 0, // ¥
      moneyAllLoading: false, // 白条结算的加载问题
      curPage: 1,
      payTypeData: {
        transactionType: 'BTJS*',
      },
      fullscreenLoading: null,
    };
  },
  mounted() {
    this.loadDatas();// 列表数据
  },
  methods: {
    loadDatas() {
      getwhitebarList({
        type: 'WJS',
        pageSize: 10,
        currPage: this.curPage,
      }).then((result) => {
        if (!result.success) {
          return;
        }
        const rows = result.rows || [];
        rows.forEach((item) => {
          const index = this.selectionIds.indexOf(item.orderId);
          if (index > -1) {
            item.check = true;
          } else {
            item.check = false;
          }
        });
        this.orderListData = rows;
        this.totalCount = result.totalCount;
      });
    },
    // 点击分页
    pageHandleChange(val) {
      this.curPage = val;
      this.loadDatas();
    },
    // 全选
    handleCheckAllChange(value) {
      this.indeterminate = false;
      if (value) {
        this.orderListData.forEach((item) => {
          item.check = true;
        });
        this.moneyAllLoading = true; // 白条的金额加载状态
        this.getAlldatas((list) => {
          this.moneyAll = 0;
          this.checkData = list.filter(item => item.check);
          list.forEach((item) => {
            if (item.check) {
              this.selectionIds.push(item.orderId);
              this.selectionCodes.push(item.orderBh);
              this.moneyAll += item.cjjg;
            }
          });
          this.moneyAllLoading = false; // 关闭白条的加载状态
        });
      } else {
        this.selectionIds = [];
        this.selectionCodes = [];
        this.checkData = [];
        this.moneyAll = 0;
        this.orderListData.forEach((item) => {
          item.check = false;
        });
      }
    },
    // 获取所有数据
    getAlldatas(cb) {
      this.curPage = -1;
      getwhitebarList({
        type: 'WJS',
        pageSize: 20,
        currPage: this.curPage,
      }).then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message || '获取数据失败！',
          });
        }
        const rows = result.rows || [];
        rows.forEach((item) => {
          // 如果已经勾选了全选
          if (this.checkAll) {
            item.check = true;
          } else {
            item.check = false;
          }
        });
        this.allDatas = rows;
        cb && cb(rows);
      });
    },
    // 单选
    handleCheckedCitiesChange(em) {
      if (em.check) {
        this.selectionIds.push(em.orderId);
        this.selectionCodes.push(em.orderBh);
        this.checkData.push(em);
      } else {
        const index = this.selectionIds.indexOf(em.orderId);
        if (index > -1) {
          this.selectionIds.splice(index, 1);
          this.selectionCodes.splice(index, 1);
          this.checkData = this.checkData.filter(order => order.orderId != em.orderId);
        }
      }
      if (this.checkData.length > 0) {
        this.checkAll = this.checkData.length == this.allDatas.length;
        this.checkAll && this.selectionIds.length > 0 && (this.indeterminate = false) || (this.indeterminate = true);
      } else {
        this.indeterminate = false;
      }
      this.setMoneyAll();
    },
    // 计算总金额
    setMoneyAll() {
      this.moneyAll = 0;
      this.checkData.forEach((item) => {
        if (item.check) {
          this.moneyAll += item.cjjg;
        }
      });
    },
    // 支付
    doPay(type) {
      if (!getToken()) {
        return this.$message({
          type: 'error',
          message: '您暂未登录，请先去登录！',
          duration: 5000,
        });
      }
      switch (type) {
        // 微信支付
        case 'wechat':
          this.startPaying(type, (orderInfo, payinfo) => {
            this.$refs.payDialog.qrcodeImg = payinfo.data;
            this.$refs.payDialog.ids = this.selectionIds.join(',');
            this.$refs.payDialog.orderCode = this.selectionCodes[0]; // 第一条订单编号
            this.$refs.payDialog.isBTJS = 'BTJS*';
            this.$refs.payDialog.title = '微信支付提示';
            this.$refs.payDialog.amountPayable = this.moneyAll;
            this.$refs.payDialog.payTime = payinfo.date; // 二维码生成时间
            this.$refs.payDialog.payComputeTime(); // 开始倒计时
            this.$refs.payDialog.orderId = this.selectionIds[0]; // 后台只取第一条作为展示和查询
            setTimeout(() => {
              this.fullscreenLoading.close();
              this.$refs.payDialog.payVisible = true;
            }, 500);
          });
          break;
        // 支付宝支付
        case 'ali':
          this.startPaying(type, (orderInfo, payinfo) => {
            const newTab = window.open('about:blank');
            const div = document.createElement('div');
            // newTab.document.charset = 'UTF-8';
            div.innerHTML = payinfo.data;
            newTab.document.body.appendChild(div);
            newTab.document.forms.alipaysubmit.submit();
            this.$refs.payDialog.title = '支付宝支付提示';
            this.$refs.payDialog.amountPayable = this.moneyAll;
            this.$refs.payDialog.orderId = this.selectionIds[0]; // 后台只取第一条作为展示和查询
            this.$refs.payDialog.orderCode = this.selectionCodes[0]; // 第一条订单编号
            this.$refs.payDialog.ids = this.selectionIds.join(',');
            this.$refs.payDialog.isBTJS = 'BTJS*';
            setTimeout(() => {
              this.fullscreenLoading.close();
              this.$refs.payDialog.payVisible = true;
            }, 500);
          });
          break;
        // 线下支付
        case 'XXZF':
          this.openPhone();
          break;
        default:
          break;
      }
    },
    // 立即支付
    startPaying(type, callback) {
      if (this.selectionIds.length == 0) {
        return this.$message({
          type: 'error',
          message: '请至少选择一条数据！',
        });
      }
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: '拼命加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        customClass: 'je-pay-loading',
      });
      startPay({
        type,
        ids: this.selectionCodes.join(','),
        transactionType: 'BTJS*',
      }).then((result) => {
        if (!result.success) {
          this.fullscreenLoading.close();
          if (result.errorCode == '5101') {
            return this.$message({
              type: 'error',
              message: '当前支付方式异常，请切换支付方式，或者稍后重试！',
            });
          }
          return this.$message({
            type: 'error',
            message: result.message || '支付失败！',
          });
        }
        return callback(this.checkData[0], result.obj);
      });
    },
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../../style/shop/util';
.settleTable {
  height: 400px;
  overflow-y: auto;
}
</style>
<style lang='less'>
.settle-dialog {
  max-height: 100vh!important;
  .is-indeterminate {
    .el-checkbox__inner{
      background: #f6474e;
    border-color: #f6474e;
    }
    .el-checkbox__inner::before {
      left: 9px;
      top: 17px;
      margin-top: -50%;
      margin-left: -50%;
    }
  }
  .el-dialog__title {
    color: #3f3f3f;
    font-weight: 600;
    font-size: 24px;
  }
  .el-dialog__body {
    padding: 0 30px 40px;
  }
  .orderDate {
    padding-left: 80px;
  }
  .orderNum {
    padding-left: 35px;
  }
  .el-pagination {
    text-align: right;
    padding-right: 20px;
    .el-pagination__editor {
      width: 50px;
    }
  }
  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .el-checkbox__inner::after {
    left: 16px;
    top: 13px;
    margin-top: -50%;
    margin-left: -50%;
  }
  .is-checked {
    .el-checkbox__inner {
      background: #f6474e;
      border-color: #f6474e;
    }
    .el-checkbox__label {
      color: #f6474e;
    }
  }
  .elemnt-input-apply .el-input__inner {
    border: none;
    border-radius: 20px;
  }
  .el-pagination.is-background .el-pager .active {
    background-color: #f6474e !important;
  }
  .height-align {
    display: flex;
    align-items: center;
  }
  .line_con {
    box-sizing: border-box;
    width: 100%;
    padding-right: 20px;
  }
  .paytype-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px dashed rgba(169,171,169,1);
    padding: 40px;
    border-radius:4px;
    & > div {
      text-align: center;
      cursor: pointer;
      transition: all .5s;
      i {
        font-size: 60px;
        &.jeicon-wechatpay {
          color: #24AF41;
        }
        &.jeicon-alipay {
          color: #069CE7;
        }
        &.jeicon-rmb-on {
          color: #FBB11A;
        }
      }
      div {
        font-size: 12px;
        margin-top: 20px;
        font-weight: 600;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.je-pay-loading {
  .el-loading-spinner {
    i {
      font-size: 50px;
    }
    .el-loading-text {
      font-size: 24px;
    }
  }
}
</style>
